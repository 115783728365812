<template>
  <div
    class="shadow-md rounded-md lg:px-20 px-10 py-14 bg-white w-full mx-auto my-5"
    :style="{'max-width': '34.375rem'}"
  >
    <div
      class="flex lg:flex-row flex-col lg:justify-between justify-center lg:items-end items-center"
    >
      <p
        class="text-xl text-ash-800 whitespace-nowrap"
      >RESET YOUR PASSWORD?</p>
      <div>
      <div
        class="flex items-center"
      >
        <router-link
          :to="{
            name: 'Login'
          }"
          class="gtm-resetpassword-link flex items-center"
        >
          <DgIcon
            size="7"
            name="chevron-left"
            color="ash-600"
            class="mr-3"
          />
          <p
            class="text-base text-ash-600 whitespace-nowrap"
          >返回登入</p>
        </router-link>
      </div>
      </div>
    </div>
    <p
      class="text-base text-ash-600 pt-8 pb-5 mb-1 text-center"
    >請輸入您的新密碼</p>
    <DgForm
      ref="form"
      formKey="init.resetPassword"
      class="mt-7"
      :settings="settings"
    />
    <button
      class="gtm-resetpassword-btn bg-primary-gradient-to-r py-2 text-lg text-white rounded-full
      w-full mt-14"
      @click="methodReset"
    >重置密碼</button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { cloneDeep, isEqual } from '@/lib/lodash';
import DgForm from '@/components/dgForm/Index.vue';
import DgIcon from '@/components/base/DgIcon.vue';
import requestApi from '@/lib/http';
import {
  required, min, max,
} from '@/utils/validator';

export default {
  name: 'ResetPasswordCard',
  components: {
    DgForm,
    DgIcon,
  },
  data() {
    return {
      token: null,
      settings: [
        {
          componentType: 'text',
          col: '2',
          inputType: 'password',
          uniKey: 'password',
          placeholder: '請輸入8~12位之密碼',
          rules: [
            required,
            min(8),
            max(12),
          ],
        },
        {
          componentType: 'text',
          col: '2',
          inputType: 'password',
          uniKey: 'passwordValid',
          placeholder: '請再次輸入密碼',
          rules: [
            required,
            min(8),
            max(12),
            (v, cb) => isEqual(this.$refs?.form?.value?.password, v) || cb('兩次密碼不相同'),
          ],
        },
      ],
    };
  },
  computed: {
    queryToken() {
      return this.$route?.query?.token;
    },
  },
  watch: {
    queryToken: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal) && newVal) {
          this.token = newVal;
          this.$router.replace({ query: { temp: undefined } });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      showNotifySuccess: 'setNotifySuccess',
      showNotifyError: 'setNotifyError',
      showLoading: 'setIsLoading',
    }),
    methodUpdate(key, value) {
      const result = cloneDeep(this.value);
      result[key] = value;
      this.value = result;
    },
    async methodReset() {
      if (!this.token) {
        this.showNotifyError('無效的操作');
        return;
      }
      const params = this.$refs.form.methodGetContent();
      if (!params) {
        this.showNotifyError('請檢查錯誤提示項目');
        return;
      }
      delete params.passwordValid;
      const config = {
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      };

      this.showLoading(true);
      const { status, message/* , result */ } = await requestApi('users.resetPassword', params, config);
      if (status) {
        this.$router.push({
          name: 'Login',
        });
        this.showNotifySuccess('重置成功，請用新密碼重新登入');
      } else {
        this.showNotifyError(`重置失敗，${message}`);
      }
      this.showLoading(false);
    },
  },
};
</script>
