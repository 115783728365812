<template>
  <div
    class="bg-ash-100 bg-opacity-50 flex items-center"
  >
    <div
      class="max-w-1500 mx-auto px-5 py-5"
    >
      <!-- right -->
      <div
        class="lg:pl-24 pl-0 lg:pt-4 pt-0"
      >
        <ResetPasswordCard />
      </div>
    </div>
  </div>
</template>

<script>
import ResetPasswordCard from '@/views/resetPassword/ResetPasswordCard.vue';

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordCard,
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
